import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* MEDICALHISTORYS */
export const getMedicalHistorys = createSelector(
  (state) => state.MedicalHistorys,
  (MedicalHistorys) => MedicalHistorys
);
export const getMedicalHistorysForm = createSelector(
  (state) => state.MedicalHistorysForm,
  (form) => form
);

/* CLIENTS */
export const getClients = createSelector(
  (state) => state.clients,
  (clients) => clients
);
export const getClientsForm = createSelector(
  (state) => state.clientsForm,
  (form) => form
);

/* TYPEMEDICALCONSULTATIONS */
export const getTypeMedicalConsultations = createSelector(
  (state) => state.typeMedicalConsultations,
  (typeMedicalConsultations) => typeMedicalConsultations
);
export const getTypeMedicalConsultationsForm = createSelector(
  (state) => state.typeMedicalConsultationsForm,
  (form) => form
);

/* EMPLOYEES */
export const getEmployees = createSelector(
  (state) => state.employees,
  (employees) => employees
);
export const getEmployeesForm = createSelector(
  (state) => state.employeesForm,
  (form) => form
);

/* MedicalConsultations */
export const getMedicalConsultations = createSelector(
  (state) => state.medicalConsultations,
  (medicalConsultations) => medicalConsultations
);
export const getMedicalConsultationsForm = createSelector(
  (state) => state.medicalConsultationsForm,
  (form) => form
);

/* MEDICALAPPOINTMENTS */
export const getMedicalAppointments = createSelector(
  (state) => state.medicalAppointments,
  (medicalAppointments) => medicalAppointments
);
export const getMedicalAppointmentsForm = createSelector(
  (state) => state.medicalAppointmentsForm,
  (form) => form
);

/* CONFIRMCONSULTS */
export const getConfirmConsults = createSelector(
  (state) => state.confirmConsults,
  (confirmConsults) => confirmConsults
);
export const getConfirmConsultsForm = createSelector(
  (state) => state.confirmConsultsForm,
  (form) => form
);

/* MYCONSULTS */
export const getMyConsults = createSelector(
  (state) => state.MyConsults,
  (MyConsults) => MyConsults
);
export const getMyConsultsForm = createSelector(
  (state) => state.MyConsultsForm,
  (form) => form
);

/* TIMETOCONSULTATIONS */
export const getTimeToConsultations = createSelector(
  (state) => state.timeToConsultations,
  (timeToConsultations) => timeToConsultations
);
export const getTimeToConsultationsForm = createSelector(
  (state) => state.timeToConsultationsForm,
  (form) => form
);

/* room */
export const getRoom = createSelector(
  (state) => state.room,
  (room) => room
);
export const getRoomForm = createSelector(
  (state) => state.roomForm,
  (form) => form
);
/* CONSULTSNOTIFICATIONS */
export const getConsultsNotifications = createSelector(
  (state) => state.consultsNotifications,
  (consultsNotifications) => consultsNotifications
);
export const getConsultsNotificationsForm = createSelector(
  (state) => state.consultsNotificationsForm,
  (form) => form
);
