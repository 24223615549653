import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  MedicalHistorys,
  MedicalHistorysForm,
  clients,
  clientsForm,
  typeMedicalConsultations,
  typeMedicalConsultationsForm,
  employees,
  employeesForm,
  medicalConsultations,
  medicalConsultationsForm,
  medicalAppointments,
  medicalAppointmentsForm,
  confirmConsults,
  confirmConsultsForm,
  MyConsults,
  MyConsultsForm,
  room,
  roomForm,
  MedicalConsultationss,
  MedicalConsultationsForm,
  consultsNotifications,
  consultsNotificationsForm,
} from "./Reducers";

export default combineReducers({
  consultsNotifications,
  consultsNotificationsForm,
  MedicalConsultationss,
  MedicalConsultationsForm,
  room,
  roomForm,
  MyConsults,
  MyConsultsForm,
  confirmConsults,
  confirmConsultsForm,
  medicalAppointments,
  medicalAppointmentsForm,
  medicalConsultations,
  medicalConsultationsForm,
  employees,
  employeesForm,
  typeMedicalConsultations,
  typeMedicalConsultationsForm,
  clients,
  clientsForm,
  MedicalHistorys,
  MedicalHistorysForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
